import React from 'react';
import SectionTitle from '../common/SectionTitle';

const points = [
  {
      'title': 'World-Class Georgian Talents',
      'description': 'Access a pool of globally recognized professionals from Georgia, experienced in working remotely with international clients.'
  },
  {
      'title': 'Convenient Time Zone (+4 UTC)',
      'description': 'Working hours closely align with many global business hours.'
  },
  {
      'title': 'Cost-Effective Solutions',
      'description': 'High-quality talent at competitive rates, offering excellent return on investment.'
  },
  {
      'title': 'Cultural Compatibility',
      'description': 'A blend of Eastern and Western influences makes professionals adaptable in diverse teams.'
  },
  {
      'title': 'EU Candidate Country Advantage',
      'description': 'High standard of professional expertise and cultural alignment due to Georgia\'s EU candidacy.'
  },
  {
      'title': 'Stable Business Environment',
      'description': 'Political and economic stability in Georgia supports a reliable remote workforce.'
  },
  {
      'title': 'Emerging Tech Hub',
      'description': 'Access to talent from a growing tech hub, skilled in the latest trends and technologies.'
  },
  {
      'title': 'Educated and Skilled Workforce',
      'description': 'High-quality professionals, in IT and tech sectors, due to qualification enhancement programs and a strong education system.'
  },
];


const FeatureImgSeven = () => {
  return (
    <>
      <section className='why-choose-us pt-60 pb-120'>
        <div className='container'>
          <div className='row justify-content-lg-between justify-content-center align-items-center'>
            <div className='col-lg-5 col-md-7 order-1 order-lg-0'>
              <div className='why-choose-img position-relative'>
                <img
                  src='assets/img/feature-hero-img-2.svg'
                  className='img-fluid'
                  alt='duel-phone'
                />
              </div>
            </div>
            <div className='col-lg-6 col-md-12 order-0 order-lg-1'>
                <SectionTitle
                  title='Why Georgia?'
                  description=''
                  leftAlign
                />
                <ul className='list-unstyled mb-0'>
                    {
                      points.map(point => (
                        <li className='d-flex align-items-start mb-4'>
                          <div className='icon-content'>
                            <h3 className='h5'>{point.title}</h3>
                            <p>{point.description}</p>
                          </div>
                        </li>
                      ))
                    }
                </ul>
              </div>
              </div>
            </div>
      </section>
    </>
  );
};

export default FeatureImgSeven;
