import React from 'react';
import SectionTitle from '../common/SectionTitle';

const PromoTwo = () => {
  return (
    <>
      <section className='promo-section ptb-120'>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-lg-6 col-md-10'>
              <SectionTitle
                title='Our Services'
                description='   '
                centerAlign
              />
            </div>
          </div>
          <div className='row'>
            <div className='col-lg-4 col-md-6'>
              <div className='promo-single position-relative text-center bg-white custom-shadow rounded-custom p-5 mb-4'>
                <div className='promo-icon mb-32'>
                  <i className='fas fa-wifi text-primary fa-3x'></i>
                </div>
                <div className='promo-info'>
                  <h3 className='h5'>Sourcing</h3>
                  <p className='mb-0'>
                  We pinpoint and attract top-tier talent perfectly matched to your project needs. Our expertise ensures a seamless fit with your company's culture and goals.
                  </p>
                </div>
                {/* <!--pattern start--> */}
                <div className='dot-shape-bg position-absolute z--1 left--40 top--40'>
                  <img src='assets/img/shape/dot-big-square.svg' alt='shape' />
                </div>
                {/* <!--pattern end--> */}
              </div>
            </div>
            <div className='col-lg-4 col-md-6'>
              <div className='promo-single position-relative text-center bg-white custom-shadow rounded-custom p-5 mb-4'>
                <div className='promo-icon mb-32'>
                  <i className='fa-solid fa-list-check text-success fa-3x'></i>
                </div>
                <div className='promo-info'>
                  <h3 className='h5'>Assessment</h3>
                  <p className='mb-0'>
                  Our rigorous vetting process assesses both technical skills and soft competencies, ensuring candidates meet your high standards.
                  </p>
                </div>
              </div>
            </div>
            <div className='col-lg-4 col-md-6'>
              <div className='promo-single position-relative text-center bg-white custom-shadow rounded-custom p-5 mb-4'>
                <div className='promo-icon mb-32'>
                  <i className='fa-solid fa-briefcase text-danger fa-3x'></i>
                </div>
                <div className='promo-info'>
                  <h3 className='h5'>Management</h3>
                  <p className='mb-0'>
                  We take care of all HR responsibilities from payroll to benefits, allowing you to focus on your core operations.
                  </p>
                </div>
                {/* <!--pattern start--> */}
                <div className='dot-shape-bg position-absolute z--1 right--40 bottom--40'>
                  <img src='assets/img/shape/dot-big-square.svg' alt='shape' />
                </div>
                {/* <!--pattern end--> */}
              </div>
            </div>
          </div>
          {/* <div className='customer-section pt-60'>
            <div className='container'>
              <div className='row justify-content-center'>
                <div className='col-lg-8 col-12'>
                  <div className='customer-logos-grid text-center'>
                    <img
                      src='assets/img/clients/client-logo-1.svg'
                      width='150'
                      alt='clients logo'
                      className='img-fluid p-1 px-md-2 p-lg-3 m-auto'
                    />
                    <img
                      src='assets/img/clients/client-logo-2.svg'
                      width='150'
                      alt='clients logo'
                      className='img-fluid p-1 px-md-2 p-lg-3 m-auto'
                    />
                    <img
                      src='assets/img/clients/client-logo-3.svg'
                      width='150'
                      alt='clients logo'
                      className='img-fluid p-1 px-md-2 p-lg-3 m-auto'
                    />
                    <img
                      src='assets/img/clients/client-logo-4.svg'
                      width='150'
                      alt='clients logo'
                      className='img-fluid p-1 px-md-2 p-lg-3 m-auto'
                    />
                    <img
                      src='assets/img/clients/client-logo-5.svg'
                      width='150'
                      alt='clients logo'
                      className='img-fluid p-1 px-md-2 p-lg-3 m-auto'
                    />
                    <img
                      src='assets/img/clients/client-logo-6.svg'
                      width='150'
                      alt='clients logo'
                      className='img-fluid p-1 px-md-2 p-lg-3 m-auto'
                    />
                    <img
                      src='assets/img/clients/client-logo-7.svg'
                      width='150'
                      alt='clients logo'
                      className='img-fluid p-1 px-md-2 p-lg-3 m-auto'
                    />
                    <img
                      src='assets/img/clients/client-logo-8.svg'
                      width='150'
                      alt='clients logo'
                      className='img-fluid p-1 px-md-2 p-lg-3 m-auto'
                    />
                  </div>
                  <p className='text-center mt-5 mb-0 h6'>
                    Trusted More than 25,00+ Companies Around the World
                  </p>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </section>
    </>
  );
};

export default PromoTwo;
