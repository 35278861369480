import React from 'react';
import { toast } from 'react-toastify';

const ContactsForm = () => {
  const handleSubmit = (e) => {
    e.preventDefault();
    const URL = '/api/contact';
    const formData = new FormData(e.target);
    const plainFormData = Object.fromEntries(formData.entries());
    const formDataJsonString = JSON.stringify(plainFormData);
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: formDataJsonString,
    };

    fetch(URL, requestOptions)
      .then((response) => {
        toast.info("Your message has been sent successfully!");
      })
      .catch((error) => {
        toast.error("An error occurred while sending your message!");
        console.error(error);
      });
  }

  return (
    <>
      <div className='col-xl-5 col-lg-7 col-md-12 order-0 order-lg-1' id="contact-form">
        <div className='register-wrap p-5 bg-white shadow rounded-custom mt-5 mt-lg-0 mt-xl-0'>
          <h3 className='fw-medium h4'>
            Fill out the form and we'll be in touch as soon as possible.
          </h3>

          <form action='/api/index' className='mt-4 register-form' method='POST' onSubmit={handleSubmit}>
            <div className='row'>
              <div className='col-sm-6'>
                <div className='input-group mb-3'>
                  <input
                    name='name'
                    type='text'
                    className='form-control'
                    placeholder='Name'
                    aria-label='name'
                  />
                </div>
              </div>
              <div className='col-sm-6 '>
                <div className='input-group mb-3'>
                  <input
                    name='email'
                    type='email'
                    className='form-control'
                    placeholder='Email'
                    aria-label='email'
                  />
                </div>
              </div>
              <div className='col-sm-6'>
                <div className='input-group mb-3'>
                  <input
                    name='company_website'
                    type='text'
                    className='form-control'
                    placeholder='Company website'
                    aria-label='company-website'
                  />
                </div>
              </div>
              <div className='col-sm-6'>
                <div className='input-group mb-3'>
                  <input
                    name='position'
                    type='text'
                    className='form-control'
                    placeholder='Position'
                    aria-label='position'
                  />
                </div>
              </div>
              <div className='col-12'>
                <div className='input-group mb-3'>
                  <textarea
                    name='message'
                    className='form-control'
                    placeholder='Tell us more'
                    style={{ height: '120px' }}
                  ></textarea>
                </div>
              </div>
              <div className='col-12'>
                <button
                  type='submit'
                  className='btn btn-primary mt-4 d-block w-100'
                >
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default ContactsForm;
