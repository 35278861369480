import React from 'react';
import SectionTitle from '../common/SectionTitle';

const points = [
  {
      'title': 'Client-Centric Approach',
      'description': 'Our strategies prioritize your business objectives and project outcomes in every recruitment endeavor.'
  },
  {
      'title': 'IT Specialization',
      'description': 'We focus on sourcing skilled IT Professionals and ensures a perfect match for your technical requirements.'
  },
  {
      'title': 'Effortless Recruitment',
      'description': 'We manage all aspects of recruitment and employee management, such as paperwork, taxes, and benefits.'
  },
  {
      'title': 'Rigorous Selection Process',
      'description': 'Candidates undergo thorough interviews and technical evaluations to meet your specific needs.'
  },
  {
      'title': 'English Proficiency',
      'description': 'Seamless communication with fluent English-speaking candidates.'
  },
  {
      'title': 'Flexible Staffing Solutions',
      'description': 'Easily adjust team size or swap candidates to align with project demands.'
  },
  {
      'title': 'Short-Term Expertise',
      'description': 'Quickly hire experts for short-term projects without long-term commitments.'
  },
  {
      'title': 'Comprehensive Support',
      'description': 'Continuous and post-placement support ensures candidate satisfaction and smooth integration into your team.'
  },
];

const FeatureImgSix = () => {
  return (
    <>
      <section className='feature-section-two ptb-120'>
        <div className='container'>
          <div className='row align-items-center justify-content-lg-between justify-content-center'>
            <div className='col-lg-6 col-md-12'>
              <div className='feature-content-wrap'>
                <SectionTitle
                  title='Why Choose Us?'
                  description=''
                  leftAlign
                />
                <ul className='list-unstyled mb-0'>
                    {
                      points.map(point => (
                        <li className='d-flex align-items-start mb-4'>
                          <div className='icon-content'>
                            <h3 className='h5'>{point.title}</h3>
                            <p>{point.description}</p>
                          </div>
                        </li>
                      ))
                    }
                </ul>
              </div>
            </div>
            <div className='col-lg-6 col-md-7'>
              <div className='feature-img-wrap'>
                <img
                  src='assets/img/feature-hero-img.svg'
                  alt='feature'
                  className='img-fluid rounded-custom'
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default FeatureImgSix;
